<template>
  <v-container
    id="user-profile"
    fluid
    tag="section"
  >
    <v-row justify="center">
      <v-col
        cols="12"
        md="8"
      >
        <base-material-card color="primary">
          <template v-slot:heading>
            <div class="display-2 font-weight-light">
              Edit Profile
            </div>

            <div class="subtitle-1 font-weight-light">
              Complete your profile
            </div>
          </template>

          <v-form>
            <v-container class="py-0">
              <v-row>
                <v-col
                  cols="12"
                  md="6"
                >
                  <v-text-field
                    v-model="profile.username"
                    maxlength="32"
                    :error="$v.profile.username.$error"
                    :error-messages="$v.profile.username.$error && !$v.profile.username.isUsernameValid ? 'Username must not contain spaces' : []"
                    class="purple-input"
                    label="User Name"
                  />
                </v-col>

                <v-col
                  cols="12"
                  md="6"
                >
                  <v-text-field
                    label="Email Address"
                    class="purple-input"
                    disabled
                    :value="profile.email"
                  />
                </v-col>

                <v-col
                  cols="12"
                  md="6"
                >
                  <v-text-field
                    v-model="profile.firstName"
                    :error="$v.profile.firstName.$error"
                    :error-messages="$v.profile.firstName.$error && !$v.profile.firstName.required ? 'First name is required' : []"
                    maxlength="32"
                    label="First Name"
                    class="purple-input"
                  />
                </v-col>

                <v-col
                  cols="12"
                  md="6"
                >
                  <v-text-field
                    v-model="profile.lastName"
                    :error="$v.profile.lastName.$error"
                    :error-messages="$v.profile.lastName.$error && !$v.profile.lastName.required ? 'Last name is required' : []"
                    maxlength="32"
                    label="Last Name"
                    class="purple-input"
                  />
                </v-col>

                <v-col
                  cols="12"
                  md="6"
                >
                  <v-text-field
                    v-model="profile.phone"
                    :error="$v.profile.phone.$error"
                    :error-messages="$v.profile.phone.$error && !$v.profile.phone.numeric ? 'Phone number must be numeric' : []"
                    maxlength="15"
                    label="Phone"
                    class="purple-input"
                  />
                </v-col>

                <v-col
                  cols="12"
                  md="6"
                >
                  <v-select
                    v-model="profile.country"
                    :items="countries"
                    label="country"
                  />
                </v-col>

                <v-col
                  cols="12"
                  class="text-right"
                >
                  <v-btn
                    :loading="isLoading"
                    color="primary"
                    class="mr-0"
                    @click="updateProfile"
                  >
                    Update Profile
                  </v-btn>
                </v-col>
                <v-col>
                  <v-alert
                    v-model="alert"
                    border="left"
                    close-text="Close Alert"
                    :type="status"
                    dismissible
                  >
                    {{ message }}
                  </v-alert>
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </base-material-card>
      </v-col>

      <v-col
        cols="12"
        md="4"
      >
        <base-material-card
          class="v-card-profile"
          :avatar="photoUrl"
        >
          <v-card-text class="text-center">
            <h6 class="display-1 mb-1 grey--text text-uppercase">
              {{ currentUser && currentUser.role }}
            </h6>

            <h4 class="display-2 font-weight-light mb-3">
              {{ currentUser && currentUser.firstName }} {{ currentUser && currentUser.lastName }}
            </h4>

            <p class="font-weight-light grey--text">
              {{ currentUser && currentUser.email }}
            </p>
          </v-card-text>
        </base-material-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  import { mapActions, mapState } from 'vuex'
  import { required, maxLength, helpers, numeric } from 'vuelidate/lib/validators'
  import { redirectMixin } from '@/mixins/redirectMixin'

  const isUsernameValid = helpers.regex('isUsernameValid', /^\S+$/)

  export default {
    mixins: [redirectMixin],
    data () {
      return {
        countries: this.$store.state.countries,
        alert: false,
        message: '',
        status: 'info',
      }
    },
    validations: {
      profile: {
        firstName: { required, maxLength: maxLength(32) },
        lastName: { required, maxLength: maxLength(32) },
        phone: { maxLength: maxLength(15), numeric },
        username: { maxLength: maxLength(32), isUsernameValid },
      },
    },
    computed: {
      ...mapState({
        currentUser: state => state.userData.currentUser,
        profile: state => state.profileData.profile,
        isLoading: state => state.profileData.isLoading,
        profileError: state => state.profileData.profileError,
      }),
      photoUrl () {
        if (this.profile) {
          const { photo } = this.profile
          if (photo) {
            return photo.url
          }
          return 'null'
        }
        return 'null'
      },
    },
    watch: {
      profile: function () {
        const { firstName, message } = this.profile
        if (message === 'Profile updated') {
          this.message = `Hi ${firstName}, your profile has been updated`
          this.status = 'success'
          this.alert = true
          this.getCurrentUser()
        }
      },
      profileError: function () {
        const { statusCode } = this.profileError
        const { email } = this.currentUser
        if (statusCode === 401) {
          this.redirectToSignin(email, 'Kindly log in again, your session may have expired or you may have logged out from another device')
        } else if (statusCode === 403) {
          this.message = 'You current password is incorrect, you will be redirected to the login page'
          this.status = 'error'
          this.alert = true
          setTimeout(() => {
            this.redirectToSignin(email, 'The current password you entered was incorrect kindly login and try again.')
          }, 3000)
        } else {
          if (this.profileError === 'Network Error') {
            this.message = this.profileError
            this.status = 'error'
            this.alert = true
          } else {
            this.message = 'Your profile was not updated'
            this.status = 'error'
            this.alert = true
          }
        }
      },
    },
    created () {
      const { token, id } = this.currentUser
      this.getMyProfile({ id, token })
    },
    methods: {
      ...mapActions('profileData', ['getMyProfile', 'updateMyProfile']),
      ...mapActions('userData', ['getCurrentUser']),
      updateProfile () {
        this.$v.$touch()
        if (!this.$v.$invalid) {
          const { firstName, lastName, phone, username, country } = this.profile
          const { id, token } = this.currentUser
          this.updateMyProfile({
            id,
            token,
            user: {
              firstName,
              lastName,
              phone,
              username,
              country,
            },
          })
        }
      },
    },
  }
</script>
